import { dialogAtom, initialDialogValue } from '@/state/Dialog/atom'
import {
  type DialogSessionItem,
  type OpenDialogData,
  type OpenDialogType,
} from '@/state/Dialog/types'
import { useSetAtom } from 'jotai'
import { useCallback } from 'react'
// eslint-disable-next-line no-restricted-imports -- this needs to be updated to use useAtom or useAtomValue
import { getJotaiStore } from '@/state'
import {
  InitialDialogState,
  useDialogContext,
} from '@/containers/DialogContainer'
import { isProduction } from '@/utils/environment'
import { useStoreContext } from '@/context/GlobalStoreContext'
import { logError } from '@/utils/logger'
import { isObject } from '@/utils/isObject'

export const useHandleDialog = () => {
  const dialogContext = useDialogContext()
  const setDialog = useSetAtom(dialogAtom)
  const globalStoreParams = useStoreContext()

  const openDialog = useCallback(
    <T extends OpenDialogType>(
      dialogTypeOrComponent: T,
      ...dialogData: OpenDialogData<T>
    ) => {
      const data = dialogData[0]
      const props = {
        ...data,
        globalStoreParams: data?.globalStoreParams ?? globalStoreParams,
      }
      if (typeof dialogTypeOrComponent === 'string') {
        setDialog((currentDialog) => ({
          ...currentDialog,
          type: dialogTypeOrComponent,
          data: props,
        }))
        dialogContext.setDialogState(InitialDialogState)
      } else {
        if (
          !isObject(dialogTypeOrComponent) ||
          !dialogTypeOrComponent.render?.displayName
        ) {
          const errorMessage =
            'openDialog must be called with a dynamic imported dialog'
          logError(errorMessage)
          if (!isProduction) throw new Error(errorMessage)
        }
        dialogContext.setDialogState({
          component: dialogTypeOrComponent,
          props,
        })
        setDialog(initialDialogValue)
      }
    },
    [setDialog, globalStoreParams, dialogContext]
  )

  const setDialogSessionItem = useCallback(
    (key: string, data: DialogSessionItem) => {
      setDialog((currentDialog) => ({
        ...currentDialog,
        dialogSessionStorage: {
          ...currentDialog.dialogSessionStorage,
          [key]: data,
        },
      }))
    },
    [setDialog]
  )

  const getDialogSessionItem = (key: string) => {
    const jotaiStore = getJotaiStore()
    const dialog = jotaiStore.get(dialogAtom)
    return dialog.dialogSessionStorage?.[key]
  }

  const clearDialogSessionItem = useCallback(
    (key: string) =>
      setDialog((currentDialog) => ({
        ...currentDialog,
        dialogSessionStorage: {
          ...currentDialog.dialogSessionStorage,
          [key]: undefined,
        },
      })),
    [setDialog]
  )

  return {
    openDialog,
    closeDialog: dialogContext.closeDialog,
    setDialogSessionItem,
    getDialogSessionItem,
    clearDialogSessionItem,
  } as const
}
