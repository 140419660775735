import {
  useState,
  createContext,
  useMemo,
  useContext,
  useRef,
  type ReactNode,
  type MutableRefObject,
} from 'react'
import { modalComponents } from '@/components/Modal/ModalComponents'
import { dialogAtom, initialDialogValue } from '@/state/Dialog/atom'
import { useAtom } from 'jotai'
import { type DynamicDialogComponent } from '@/state/Dialog/types'
import { DialogContainerTrackComponent } from '@/containers/DialogContainer/DialogContainerTrackComponent'
import { GlobalStoreContextProvider } from '@/context/GlobalStoreContext'
import { type StoreParams } from '@/utils/dataFetching/storeParams'

type DialogContentState = {
  component: DynamicDialogComponent | null
  props:
    | (Record<string, unknown> & { globalStoreParams: StoreParams | undefined })
    | undefined
}

export const InitialDialogState: DialogContentState = {
  component: null,
  props: { globalStoreParams: undefined },
}

export const DialogContainer = ({ children }: { children: ReactNode }) => {
  // v1: using Jotai
  const [{ data, type }, setDialog] = useAtom(dialogAtom)
  const { globalStoreParams: jotaiGlobalStoreParams, ...restData } = data ?? {}

  // v2: using local state
  const [dialogState, setDialogState] = useState(InitialDialogState)
  const { globalStoreParams: stateGlobalStoreParams, ...restStateData } =
    dialogState.props ?? {}
  // this is to get the current dialog content state without
  // the need to re-render the component due to state changes
  const getDialogState = useRef(() => dialogState)

  const ModalComponentV1 = type ? modalComponents[type] : null
  const ModalComponentV2 = dialogState.component

  const value = useMemo((): DialogContextType => {
    const onSetDialogState = (state: DialogContentState) => {
      setDialogState(state)
      getDialogState.current = () => state
    }
    return {
      getDialogState,
      setDialogState: onSetDialogState,
      closeDialog: () => {
        setDialog(initialDialogValue)
        onSetDialogState(InitialDialogState)
      },
    }
  }, [setDialog])

  return (
    <DialogContext.Provider value={value}>
      {ModalComponentV1 ? (
        <DialogStoreContextProvider globalStoreParams={jotaiGlobalStoreParams}>
          <ModalComponentV1
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any -- used for mapped components
            {...(restData as any)}
            onClose={value.closeDialog}
          />
          <DialogContainerTrackComponent type={type} />
        </DialogStoreContextProvider>
      ) : ModalComponentV2 ? (
        <DialogStoreContextProvider globalStoreParams={stateGlobalStoreParams}>
          <ModalComponentV2 {...restStateData} onClose={value.closeDialog} />
        </DialogStoreContextProvider>
      ) : null}
      {children}
    </DialogContext.Provider>
  )
}

const DialogStoreContextProvider = ({
  globalStoreParams,
  children,
}: {
  globalStoreParams: StoreParams | undefined
  children: ReactNode
}) =>
  globalStoreParams?.store_id ? (
    <GlobalStoreContextProvider storeParams={globalStoreParams}>
      {children}
    </GlobalStoreContextProvider>
  ) : (
    children
  )

type DialogContextType = {
  getDialogState: MutableRefObject<() => DialogContentState>
  setDialogState: (params: DialogContentState) => void
  closeDialog: () => void
}
export const DialogContext = createContext<DialogContextType | null>(null)

export const useDialogContext = () => {
  const context = useContext(DialogContext)
  if (!context) {
    throw new Error(
      'useDialogContext must be used within a DialogContextProvider'
    )
  }
  return context
}
