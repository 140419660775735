import { useEffect } from 'react'
import { trackElementViewed } from '@/analytics/element'
import { type DialogStateType } from '@/state/Dialog/types'

export const DialogContainerTrackComponent = ({
  type,
}: {
  type: DialogStateType
}) => {
  useEffect(() => {
    type &&
      trackElementViewed({
        type: 'modal',
        content: type,
        message_goal: 'Track how many times a modal is opened',
      })
  }, [type])

  return null
}
