import '@/lib/wdyr' // must be the first import
import '@/shims'
import '@/from-entries'
import 'intersection-observer'
import { startBugsnagAndSetContext } from '@/lib/bugsnag'
import {
  useEffect,
  useState,
  Suspense,
  lazy,
  useMemo,
  type ReactNode,
  type ReactElement,
} from 'react'
import dynamic from 'next/dynamic'
import { type NextPage } from 'next'
import { useIsomorphicLayoutEffect } from 'react-use'
import { HydrationBoundary, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { type AppProps } from 'next/app'
import Head from 'next/head'
import styled, { ThemeProvider } from 'styled-components'
import { getAnonymousId } from '@shipt/analytics-member-web'
import { useInitApp } from '@/index'
import { transformRoutesToTitle } from '@/utils/transformRoutesToTitle'
import '@/styles/react-modal-override.css'
import '../../node_modules/normalize.css/normalize.css'
import { ExperimentWidget } from '@/components/Developer/ExperimentWidget'
import { ErrorPage } from '@/components/ErrorPage'
import { ThirdPartyScripts } from '@/lib/ThirdPartyScripts'
import { setupReactModal } from '@/lib/setupReactModal'
import { useRoutingFlow } from '@/utils/appStart/useRoutingFlow'
import { useRouteChangeTracking } from '@/utils/appStart/useRouteChangeTracking'
import { routes } from '@shared/constants/routes'
import { AppFooter } from '@/components/AppFooter'
import { useReactQuery } from '@/utils/dataFetching/reactQuery/SegwayQueryClient'
import { GlobalStyle } from '@/styles/globalStyles'
import { GuestUserContextProvider } from '@/context/GuestUserContext'
import { useQueryUser } from '@/services/User/queries'
import {
  type PageRouteData,
  type CombinedPageProps,
} from '@/utils/setPageProps'
import { Provider as JotaiProvider } from 'jotai'
import { useCreateJotaiStore } from '@/state'
import { ShiptStaticData } from '@/constants/shiptStaticData'
import { ClientOnly } from '@/components/ClientOnly'
import { UserProvider as Auth0UserProvider } from '@auth0/nextjs-auth0/client'
import { useSyncUser } from '@/utils/authentication/hooks/useSyncUser'
import { shouldUseNewAuth } from '@/utils/authentication/utils'
import { hasLegacyClientAuthData } from '@/utils/authentication/client/legacy/utils'
import { LazyMotion } from 'framer-motion'
import { LoginChallengeProvider } from '@/context/LoginChallengeProvider'
import {
  useLoadFeatureFlagsIntoAtom,
  useSyncFeatureFlagsWithGlobalVariable,
} from '@/state/FeatureFlags/hooks'
import {
  useLoadExperimentsIntoAtom,
  useSyncExperimentsWithGlobalVariable,
} from '@/state/Experiments/hooks'
import { useContentsquareSyncVars } from '@/lib/contentsquare/hooks'
import { useDataRights } from '@/services/DataPrivacy/hooks'
import { OptOutDialog } from '@/components/OptOutDialog'
import { useCleanUrlOfRedirectParams } from '@/utils/appStart/useCleanUrlOfRedirectParams'
import { useScrollRestoration } from '@/utils/appStart/useScrollRestoration'
import { useLogExcessiveQueryObservers } from '@/utils/appStart/useLogExcessiveQueryObservers'
import { useQueryOptOutRestrictions } from '@/services/DataPrivacy/queries'
import { DialogContainer } from '@/containers/DialogContainer'
import { useMonitorUser } from '@/utils/authentication/hooks/useMonitorUser'
import { useFacebookSunsetModal } from '@/utils/appStart/useFacebookSunsetModal'
import { RouteDataContextProvider } from '@/context/RouteDataContext'
import { useAuth0MigrationModal } from '@/utils/appStart/useAuth0MigrationModal'
import { removeItem } from '@/utils/localStorage'

setupReactModal()

const AuthenticatedUserStatePreloader = dynamic(() =>
  import('@/utils/appStart/AuthenticatedUserStatePreloader').then(
    (m) => m.AuthenticatedUserStatePreloader
  )
)
const SimpleHeader = dynamic(() =>
  import('@/components/Headers/SimpleHeader').then((m) => m.SimpleHeader)
)
const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(
    (d) => ({ default: d.ReactQueryDevtools })
  )
)
const Toast = dynamic(() => import('@/components/Toast').then((m) => m.Toast), {
  ssr: false,
})

const loadFeatures = async () =>
  (await import('@/lib/framerMotion')).domAnimation

const DevOnlyJotaiDevTools =
  process.env.NODE_ENV === 'development' &&
  process.env.NEXT_PUBLIC_DEV_TOOLS === 'true'
    ? dynamic(
        () =>
          import('@/lib/jotaiDevTools').then((m) => ({ default: m.DevTools })),
        { ssr: false }
      )
    : null

const RouteChangeTracking = () => {
  useRouteChangeTracking()
  return null
}

const ThirdPartySideEffects = () => {
  useContentsquareSyncVars()
  useMonitorUser()
  return null
}

const PreloadAppState = () => {
  useLoadFeatureFlagsIntoAtom()
  useLoadExperimentsIntoAtom()
  return null
}

const FacebookSunsetModal = ({ routeData }: { routeData: PageRouteData }) => {
  useFacebookSunsetModal(routeData)
  return null
}

const Auth0MigrationModal = ({ routeData }: { routeData: PageRouteData }) => {
  useAuth0MigrationModal(routeData)
  return null
}

const SyncStateWithTracking = () => {
  useSyncFeatureFlagsWithGlobalVariable()
  useSyncExperimentsWithGlobalVariable()
  return null
}

export type NextPageWithLayout<P = CombinedPageProps, IP = P> = NextPage<
  P,
  IP
> & {
  getHeaderLayout?: (page: ReactElement, props: HeaderLayoutProps) => ReactNode
}
type HeaderLayoutProps = Pick<AppProps, 'router'>
type AppPropsWithLayout = AppProps<CombinedPageProps> & {
  Component: NextPageWithLayout
}
function StyledApp({ Component, pageProps, router }: AppPropsWithLayout) {
  useScrollRestoration()
  useLogExcessiveQueryObservers()
  useQueryOptOutRestrictions({ notifyOnChangeProps: [] })
  const { routeData } = pageProps
  const [showExperimentDevtools, setShowExperimentDevtools] = useState(false)
  const { error: userError, data: user } = useQueryUser({
    notifyOnChangeProps: ['data', 'error'],
  })
  const { images } = ShiptStaticData
  const title = transformRoutesToTitle(router.pathname, routeData)
  const { favicon } = images
  const initApp = useInitApp()
  const syncUser = useSyncUser()
  useDataRights()

  useEffect(() => {
    window.toggleExperimentDevTools = () =>
      setShowExperimentDevtools((old) => !old)
  }, [])

  // initApp contains client-only code, so we want to run this synchronously with useLayoutEffect
  // This function is called on every page nav. It's important it only _runs_ once (ie per page refresh)!
  useIsomorphicLayoutEffect(() => {
    initApp()
    // This only runs once; run syncUser here for legacy auth
    if (hasLegacyClientAuthData()) {
      syncUser()
    }
  }, [])

  useEffect(() => {
    // Due to issues with 2fa, do not run syncUser here for legacy auth
    if (!hasLegacyClientAuthData()) {
      syncUser()
    }
  }, [syncUser])

  useRoutingFlow(routeData)

  useEffect(() => {
    if (window?.branch?.setBranchViewData) {
      window.branch.setBranchViewData({
        data: { web_anonymous_id: getAnonymousId() },
      })
    }
  }, [])

  const isPreviewPage =
    router.route.includes(routes.CMS_MODULE.url) ||
    router.route.includes(routes.CMS_PREVIEW_PAGE.url)

  if (isPreviewPage) {
    return (
      <>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>Preview</title>
        </Head>
        <Component {...pageProps} />
      </>
    )
  }
  // Use the layout defined at the page level, if available
  const getHeaderLayout = Component.getHeaderLayout ?? ((page) => page)

  if (userError) {
    return (
      <>
        <SimpleHeader />
        <ErrorPage showActionButton={false} />
      </>
    )
  }
  return (
    <>
      <ThirdPartySideEffects />
      {user?.id && <AuthenticatedUserStatePreloader pageProps={pageProps} />}
      <PreloadAppState />
      {/* Remove once the Facebook sunset migration is complete - https://app.clickup.com/t/86b0mrbga */}
      <FacebookSunsetModal routeData={routeData} />
      <Auth0MigrationModal routeData={routeData} />
      <SyncStateWithTracking />
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="format-detection"
          content="telephone=no, date=no, email=no, address=no"
        />
        <title>{title}</title>
        <link rel="shortcut icon" href={favicon} key="favicon" />
        <ThirdPartyScripts disableSegment={!!pageProps.disableSegment} />
        {/* Prevent authenticated pages from being indexed */}
        {(routeData?.authentication || routeData?.noIndex) && (
          <>
            <meta name="robots" content="noindex" />
            <meta name="googlebot" content="noindex" />
          </>
        )}
      </Head>
      <RouteChangeTracking />
      <BodyContainer id="main-content">
        <Toast />
        {getHeaderLayout(
          pageProps.statusCode ? (
            <ErrorPage statusCode={pageProps.statusCode} />
          ) : (
            <Component {...pageProps} />
          ),
          { router }
        )}
      </BodyContainer>
      <AppFooter />
      <OptOutDialog />
      {showExperimentDevtools && <ExperimentWidget />}
    </>
  )
}

const BodyContainer = styled.div`
  min-height: 100vh;
  padding-bottom: 1.5rem;
`

export default function App(props: AppProps<CombinedPageProps>) {
  useCleanUrlOfRedirectParams()
  const [showRqDevtools, setShowRqDevtools] = useState(false)
  const queryClient = useReactQuery()
  const { pageProps } = props ?? {}
  const { reactQueryState, ssrContext, routeData } = pageProps ?? {}

  startBugsnagAndSetContext(routeData)
  const newAuthEnabled = shouldUseNewAuth(ssrContext)

  const sessionFetcher = useMemo(() => {
    if (!newAuthEnabled) {
      // If we are not using new auth (aka we are using legacy auth)
      // We need to override the sessionFetcher in the auth0provider,
      // to prevent calls to /api/auth/me
      return () => Promise.resolve(undefined)
    }
    // If we are using new auth, sessionFetcher should be undefined, so the
    // default sessionFetcher is used in the auth0provider
    return undefined
  }, [newAuthEnabled])

  const auth0User = useMemo(() => {
    if (!newAuthEnabled) {
      return {}
    }
    // If we are using new auth, auth0User should be undefined (eventually a pageProp)
    // so the default behavior (fetch the user) is used in the auth0provider
    return undefined
  }, [newAuthEnabled])

  useEffect(() => {
    window.toggleRqDevTools = () => setShowRqDevtools((old) => !old)
    removeItem('state')
    removeItem('REACT_QUERY_OFFLINE_CACHE')
  }, [])

  const jotaiStore = useCreateJotaiStore()

  return (
    <LoginChallengeProvider>
      <JotaiProvider store={jotaiStore}>
        {DevOnlyJotaiDevTools && (
          <ClientOnly>
            <DevOnlyJotaiDevTools store={jotaiStore} />
          </ClientOnly>
        )}
        <QueryClientProvider client={queryClient}>
          <RouteDataContextProvider routeData={routeData}>
            <GuestUserContextProvider cookies={ssrContext?.req?.cookies}>
              <HydrationBoundary state={reactQueryState}>
                <Auth0UserProvider fetcher={sessionFetcher} user={auth0User}>
                  <ThemeProvider theme={ShiptStaticData.theme}>
                    <LazyMotion features={loadFeatures} strict>
                      <GlobalStyle />
                      <DialogContainer>
                        <StyledApp {...props} />
                      </DialogContainer>
                    </LazyMotion>
                  </ThemeProvider>
                </Auth0UserProvider>
              </HydrationBoundary>
            </GuestUserContextProvider>
          </RouteDataContextProvider>
          <ClientOnly>
            <ReactQueryDevtools
              initialIsOpen={false}
              buttonPosition={DevOnlyJotaiDevTools ? 'top-left' : 'bottom-left'}
            />
          </ClientOnly>
          {showRqDevtools && (
            <Suspense fallback={null}>
              <ReactQueryDevtoolsProduction buttonPosition="top-left" />
            </Suspense>
          )}
        </QueryClientProvider>
      </JotaiProvider>
    </LoginChallengeProvider>
  )
}
