import { DynamicGlobalCampaignDrawer } from '@/components/Drawer/GlobalCampaignDrawer/DynamicGlobalCampaignDrawer'
import { DynamicGlobalHomePageCartDrawer } from '@/components/Drawer/GlobalHomePageCartDrawer/DynamicGlobalHomePageCartDrawer'
import { DynamicGlobalStoreSelectionDrawer } from '@/components/Drawer/GlobalStoreSelectionDrawer/DynamicGlobalStoreSelectionDrawer'
import { DynamicMoreLikeThisItemsDrawer } from '@/components/Drawer/MoreLikeThisItemsDrawer/DynamicMoreLikeThisItemsDrawer'
import { DynamicItemsInOrderDrawer } from '@/components/Drawer/OrderDetailsDrawers/ItemsInOrderDrawer/DynamicItemsInOrderDrawer'
import { DynamicViewShopProgressDrawer } from '@/components/Drawer/OrderDetailsDrawers/ViewShopProgressDrawer/DynamicViewShopProgressDrawer'
import { DynamicOtherPlansDrawer } from '@/components/Drawer/OtherPlansDrawer/DynamicOtherPlansDrawer'
// import { DynamicProductNoteAndBackupDrawer } from '@/components/Drawer/ProductNoteAndBackupDrawer/ProductNoteAndBackupDrawer/DynamicProductNoteAndBackupDrawer'
import { DynamicPromotionStoresDrawer } from '@/components/Drawer/PromotionStoresDrawer/PromotionStoresDrawer/DynamicPromotionStoresDrawer'
import { DynamicSearchFiltersDrawer } from '@/components/Drawer/SearchFiltersDrawer/DynamicSearchFiltersDrawer'
import { DynamicStoreInfoDrawer } from '@/components/Drawer/StoreInfoDrawer/DynamicStoreInfoDrawer'
import { DynamicUsualsDrawer } from '@/components/Drawer/UsualsDrawers/DynamicUsualsDrawer'
import { DynamicEBTAdditionalChargeModal } from '@/components/Modal/EBTModals/EBTAdditionalChargeModal/DynamicEBTAdditionalChargeModal'
import { DynamicEbtPinModal } from '@/components/Modal/EBTModals/EbtPinModal/DynamicEbtPinModal'
import { DynamicUpdateEBTCardModal } from '@/components/Modal/EBTModals/UpdateEBTCardModal/DynamicUpdateEBTCardModal'
import { DynamicEBTHowItWorksModal } from '@/components/Modal/EBTVerificationModal/EBTHowItWorksModal/DynamicEBTHowItWorksModal'
import { DynamicSnapEBTCardVerificationModal } from '@/components/Modal/EBTVerificationModal/SnapEBTCardVerificationModal/DynamicSnapEBTCardVerificationModal'
import { DynamicExistingMemberModal } from '@/components/Modal/ExistingMemberModal/DynamicExistingMemberModal'
import { DynamicExpressCheckOutModal } from '@/components/Modal/ExpressCheckOutModal/DynamicExpressCheckOutModal'
import { DynamicGenericErrorModal } from '@/components/Modal/GenericErrorModal/DynamicGenericErrorModal'
import { DynamicGiftCardOrderOverviewModal } from '@/components/Modal/GiftCardModals/GiftCardOrderOverviewModal/DynamicGiftCardOrderOverviewModal'
import { DynamicGiftCardPurchaseConfirmationDialog } from '@/components/Modal/GiftCardModals/GiftCardPurchaseConfirmationDialog/DynamicGiftCardPurchaseConfirmationDialog'
import { DynamicGlobalPromotionTermsModal } from '@/components/Modal/GlobalPromotionTermsModal/DynamicGlobalPromotionTermsModal'
import { DynamicGuestAddressConfirmationModal } from '@/components/Modal/GuestAddressConfirmationModal/DynamicGuestAddressConfirmationModal'
import { DynamicGuestAddressModal } from '@/components/Modal/GuestAddressModal/DynamicGuestAddressModal'
import { DynamicHealthyChoiceBalanceModal } from '@/components/Modal/HealthyChoiceBalanceModal/DynamicHealthyChoiceBalanceModal'
import { DynamicInformationModal } from '@/components/Modal/InformationModal/DynamicInformationModal'
import { DynamicListsEntryPointModal } from '@/components/Modal/ListsEntryPointModal/DynamicListsEntryPointModal'
import { DynamicMemberExclusiveOfferModal } from '@/components/Modal/MemberExclusiveOfferModal/DynamicMemberExclusiveOfferModal'
import { DynamicMembershipChangeModal } from '@/components/Modal/MembershipChangeModals/MembershipChangeModal/DynamicMembershipChangeModal'
import { DynamicMembershipPurchaseModal } from '@/components/Modal/MembershipPurchaseModal/MembershipPurchaseModal/DynamicMembershipPurchaseModal'
import { DynamicMembershipSelectionModal } from '@/components/Modal/MembershipSelectionModal/DynamicMembershipSelectionModal'
import { DynamicMembershipSuccessModal } from '@/components/Modal/MembershipSuccessModal/DynamicMembershipSuccessModal'
import { DynamicUniversalSearchFilterModal } from '@/components/Modal/MobileFilterModals/UniversalSearchFilterModal/DynamicUniversalSearchFilterModal'
import { DynamicMobileSearchModal } from '@/components/Modal/MobileSearchModal/DynamicMobileSearchModal'
import { DynamicNewListAndEditListNameModal } from '@/components/Modal/NewListAndEditListNameModal/DynamicNewListAndEditListNameModal'
import { DynamicOrderProductFeedbackModal } from '@/components/Modal/OrderProductFeedbackModal/DynamicOrderProductFeedbackModal'
import { DynamicOrderRescheduleModal } from '@/components/Modal/OrderRescheduleModal/DynamicOrderRescheduleModal'
import { DynamicOutOfStockModal } from '@/components/Modal/OutOfStockModal/DynamicOutOfStockModal'
import { DynamicPaymentMethodModal } from '@/components/Modal/PaymentMethodModal/DynamicPaymentMethodModal'
import { DynamicFirstPreferredShopperModal } from '@/components/Modal/PreferredShopperModals/FirstPreferredShopperModal/DynamicFirstPreferredShopperModal'
import { DynamicPreferredShopperOnboardingModal } from '@/components/Modal/PreferredShopperModals/PreferredShopperOnboardingModal/DynamicPreferredShopperOnboardingModal'
import { DynamicPreferredShopperPendingModal } from '@/components/Modal/PreferredShopperModals/PreferredShopperPendingModal/DynamicPreferredShopperPendingModal'
import { DynamicPreferredShopperPairingModal } from '@/components/Modal/PreferredShopperPairingModal/DynamicPreferredShopperPairingModal'
import { DynamicProductInstructionsModal } from '@/components/Modal/ProductInstructionsModal/DynamicProductInstructionsModal'
import { DynamicProfileFormModal } from '@/components/Modal/ProfileFormModal/DynamicProfileFormModal'
import { DynamicPromotionAlertModal } from '@/components/Modal/PromotionAlertModal/DynamicPromotionAlertModal'
import { DynamicPromotionTermsModal } from '@/components/Modal/PromotionTermsModal/DynamicPromotionTermsModal'
import { DynamicPurchaseConfirmationModal } from '@/components/Modal/PurchaseConfirmationModal/DynamicPurchaseConfirmationModal'
import { DynamicDeliveryPlansPurchaseModal } from '@/components/Modal/PurchaseModals/DeliveryPlansPurchaseModal/DynamicDeliveryPlansPurchaseModal'
import { DynamicSnapEBTPurchaseModal } from '@/components/Modal/PurchaseModals/SnapEBTPurchaseModal/DynamicSnapEBTPurchaseModal'
import { DynamicStudentPurchaseModal } from '@/components/Modal/PurchaseModals/StudentPurchaseModal/DynamicStudentPurchaseModal'
import { DynamicRateDefaultModal } from '@/components/Modal/RateDefaultModal/DynamicRateDefaultModal'
import { DynamicReactivationModal } from '@/components/Modal/ReactivationModal/DynamicReactivationModal'
import { DynamicReceiptModal } from '@/components/Modal/ReceiptModal/DynamicReceiptModal'
import { DynamicRedeemGiftCardDialog } from '@/components/Modal/RedeemGiftCardDialog/RedeemGiftCardDialog/DynamicRedeemGiftCardDialog'
import { DynamicReportItemEducationalModal } from '@/components/Modal/ReportItemEducationalModal/DynamicReportItemEducationalModal'
import { DynamicRewardsModal } from '@/components/Modal/RewardsModal/DynamicRewardsModal'
import { DynamicSavingsInformationModal } from '@/components/Modal/SavingsInformationModal/DynamicSavingsInformationModal'
import { DynamicSearchFilterModal } from '@/components/Modal/SearchFilterModal/DynamicSearchFilterModal'
import { DynamicSelectPaymentTypeModal } from '@/components/Modal/SelectPaymentTypeModal/DynamicSelectPaymentTypeModal'
import { DynamicShiptAiLearnMoreModal } from '@/components/Modal/ShiptAiLearnMoreModal/DynamicShiptAiLearnMoreModal'
import { DynamicSnapEBTMembershipEntryModal } from '@/components/Modal/SnapEBTMembershipEntryModal/DynamicSnapEBTMembershipEntryModal'
import { DynamicSpecialRequestModal } from '@/components/Modal/SpecialRequestModal/DynamicSpecialRequestModal'
import { DynamicUsualsStoreSelectDrawer } from '@/components/Drawer/UsualsStoreSelectDrawer/DynamicUsualsStoreSelectDrawer'
import { DynamicStudentVerificationSuccessCodeModal } from '@/components/Modal/StudentVerificationModals/CodeModal/DynamicStudentVerificationCodeModal'
import { DynamicStudentVerificationSuccessEmailModal } from '@/components/Modal/StudentVerificationModals/EmailModal/DynamicStudentVerificationSuccessEmailModal'
import { DynamicStudentVerificationSuccessHowItWorksModal } from '@/components/Modal/StudentVerificationModals/HowItWorksModal/DynamicStudentVerificationSuccessHowItWorksModal'
import { DynamicReverificationSuccessModal } from '@/components/Modal/StudentVerificationModals/ReverificationSuccessModal/DynamicReverificationSuccessModal'
import { DynamicSubstitutionPreferencesInfoModal } from '@/components/Modal/SubstitutionPreferencesInfoModal/DynamicSubstitutionPreferencesInfoModal'
import { DynamicTargetCircleMastercardModal } from '@/components/Modal/TargetCircleMastercardModal/DynamicTargetCircleMastercardModal'
import { DynamicTipDefaultModal } from '@/components/Modal/TipDefaultModal/DynamicTipDefaultModal'
import { DynamicTipYourShopperModal } from '@/components/Modal/TipYourShopperModal/DynamicTipYourShopperModal'
import { DynamicTwoFactorSettingModal } from '@/components/Modal/TwoFactorSettingModal/DynamicTwoFactorSettingModal'
import { DynamicTwoFactorVerifyCodeModal } from '@/components/Modal/TwoFactorVerifyCodeModal/DynamicTwoFactorVerifyCodeModal'
import { DynamicUpgradeSuccessModal } from '@/components/Modal/UpgradeSuccessModal/DynamicUpgradeSuccessModal'
import { DynamicVariationsQuickAddModal } from '@/components/Modal/VariationsQuickAddModal/DynamicVariationsQuickAddModal'
import { DynamicCheckBenefitsModal } from '@/components/Modal/VisaModals/CheckBenefitsModal/DynamicCheckBenefitsModal'
import { DynamicEnrollmentConfirmationModal } from '@/components/Modal/VisaModals/EnrollmentConfirmationModal/DynamicEnrollmentConfirmationModal'
import { DynamicNoBenefitsModal } from '@/components/Modal/VisaModals/NoBenefitsModal/DynamicNoBenefitsModal'
import { DynamicSpecificBenefitsModal } from '@/components/Modal/VisaModals/SpecificBenefitsModal/DynamicSpecificBenefitsModal'
import { DynamicWelcomeModal } from '@/components/Modal/WelcomeModal/DynamicWelcomeModal'
import { DynamicZipCodeFormModal } from '@/components/Modal/ZipCodeFormModal/DynamicZipCodeFormModal'
import { DynamicZipNotCoveredModal } from '@/components/Modal/ZipNotCoveredModal/DynamicZipNotCoveredModal'
import { DynamicStoresListDrawer } from '@/components/Drawer/StoresListDrawer/DynamicStoresListDrawer'
import { ModalType } from '@/constants/modalTypes'

export const modalComponents = {
  [ModalType.TIP_DEFAULT_MODAL]: DynamicTipDefaultModal,
  [ModalType.PAYMENT_METHOD_MODAL]: DynamicPaymentMethodModal,
  [ModalType.SPECIAL_REQUEST_MODAL]: DynamicSpecialRequestModal,
  [ModalType.MOBILE_SEARCH_MODAL]: DynamicMobileSearchModal,
  [ModalType.REWARDS_MODAL]: DynamicRewardsModal,
  [ModalType.SEARCH_FILTER_MODAL]: DynamicSearchFilterModal,
  [ModalType.UNIVERSAL_SEARCH_FILTER_MODAL]: DynamicUniversalSearchFilterModal,
  [ModalType.PROFILE_FORM_MODAL]: DynamicProfileFormModal,
  [ModalType.ORDER_PRODUCT_FEEDBACK_MODAL]: DynamicOrderProductFeedbackModal,
  [ModalType.OUT_OF_STOCK_MODAL]: DynamicOutOfStockModal,
  [ModalType.DELIVERY_PLANS_PURCHASE_MODAL]: DynamicDeliveryPlansPurchaseModal,
  [ModalType.PURCHASE_CONFIRMATION_MODAL]: DynamicPurchaseConfirmationModal,
  [ModalType.PRODUCT_INSTRUCTIONS_MODAL]: DynamicProductInstructionsModal,
  [ModalType.MEMBERSHIP_CHANGE_MODAL]: DynamicMembershipChangeModal,
  [ModalType.MEMBERSHIP_SELECTION_MODAL]: DynamicMembershipSelectionModal,
  [ModalType.TIP_YOUR_SHOPPER_MODAL]: DynamicTipYourShopperModal,
  [ModalType.PROMOTION_TERMS_MODAL]: DynamicPromotionTermsModal,
  [ModalType.GLOBAL_PROMOTION_TERMS_MODAL]: DynamicGlobalPromotionTermsModal,
  [ModalType.REDEEM_GIFT_CARD_MODAL]: DynamicRedeemGiftCardDialog,
  [ModalType.REPORT_ITEM_EDUCATIONAL_MODAL]: DynamicReportItemEducationalModal,
  [ModalType.RECEIPT_MODAL]: DynamicReceiptModal,
  [ModalType.RATE_DEFAULT_MODAL]: DynamicRateDefaultModal,
  [ModalType.WELCOME_MODAL]: DynamicWelcomeModal,
  [ModalType.PREFERRED_SHOPPER_PAIRING_MODAL]:
    DynamicPreferredShopperPairingModal,
  [ModalType.PREFERRED_SHOPPER_PENDING_MODAL]:
    DynamicPreferredShopperPendingModal,
  [ModalType.PREFERRED_SHOPPER_FIRST_MODAL]: DynamicFirstPreferredShopperModal,
  [ModalType.ORDER_RESCHEDULE_MODAL]: DynamicOrderRescheduleModal,
  [ModalType.TWO_FACTOR_SETTING_MODAL]: DynamicTwoFactorSettingModal,
  [ModalType.TWO_FACTOR_VERIFY_CODE_MODAL]: DynamicTwoFactorVerifyCodeModal,
  [ModalType.HEALTHY_CHOICE_BALANCE_MODAL]: DynamicHealthyChoiceBalanceModal,
  [ModalType.VARIATIONS_QUICK_ADD_MODAL]: DynamicVariationsQuickAddModal,
  [ModalType.ZIP_NOT_COVERED_MODAL]: DynamicZipNotCoveredModal,
  [ModalType.SUBSTITUTION_PREFERENCES_INFO_MODAL]:
    DynamicSubstitutionPreferencesInfoModal,
  [ModalType.VISA_CHECK_BENEFITS_MODAL]: DynamicCheckBenefitsModal,
  [ModalType.VISA_NO_BENEFITS_MODAL]: DynamicNoBenefitsModal,
  [ModalType.VISA_SPECIFIC_BENEFITS_MODAL]: DynamicSpecificBenefitsModal,
  [ModalType.VISA_ENROLLMENT_CONFIRMATION_MODAL]:
    DynamicEnrollmentConfirmationModal,
  [ModalType.PROMOTION_ALERT_MODAL]: DynamicPromotionAlertModal,
  [ModalType.ZIPCODE_FORM_MODAL]: DynamicZipCodeFormModal,
  [ModalType.INFORMATION_MODAL]: DynamicInformationModal,
  [ModalType.GENERIC_ERROR_MODAL]: DynamicGenericErrorModal,
  [ModalType.EBT_PIN_MODAL]: DynamicEbtPinModal,
  [ModalType.UPDATE_EBT_CARD_MODAL]: DynamicUpdateEBTCardModal,
  [ModalType.PREFERRED_SHOPPER_ONBOARDING_MODAL]:
    DynamicPreferredShopperOnboardingModal,
  [ModalType.MEMBERSHIP_SUCCESS_MODAL]: DynamicMembershipSuccessModal,
  [ModalType.MEMBERSHIP_PURCHASE_MODAL]: DynamicMembershipPurchaseModal,
  [ModalType.SNAP_EBT_MEMBERSHIP_ENTRY_MODAL]:
    DynamicSnapEBTMembershipEntryModal,
  [ModalType.GIFT_CARD_PURCHASE_CONFIRMATION_DIALOG]:
    DynamicGiftCardPurchaseConfirmationDialog,
  [ModalType.SEARCH_FILTERS_DRAWER]: DynamicSearchFiltersDrawer,
  [ModalType.MORE_LIKE_THIS_ITEMS_DRAWER]: DynamicMoreLikeThisItemsDrawer,
  [ModalType.GLOBAL_HOMEPAGE_CART_DRAWER]: DynamicGlobalHomePageCartDrawer,
  [ModalType.STORE_INFO_DRAWER]: DynamicStoreInfoDrawer,
  [ModalType.GLOBAL_CAMPAIGN_DRAWER]: DynamicGlobalCampaignDrawer,
  [ModalType.GUEST_ADDRESS_MODAL]: DynamicGuestAddressModal,
  [ModalType.GUEST_ADDRESS_CONFIRMATION_MODAL]:
    DynamicGuestAddressConfirmationModal,
  [ModalType.REACTIVATION_MODAL]: DynamicReactivationModal,
  [ModalType.SELECT_PAYMENT_TYPE_MODAL]: DynamicSelectPaymentTypeModal,
  [ModalType.SAVINGS_INFORMATION_MODAL]: DynamicSavingsInformationModal,
  [ModalType.EXPRESS_CHECK_OUT_MODAL]: DynamicExpressCheckOutModal,
  [ModalType.MEMBER_EXCLUSIVE_OFFER_MODAL]: DynamicMemberExclusiveOfferModal,
  [ModalType.VIEW_SHOP_PROGRESS_DRAWER]: DynamicViewShopProgressDrawer,
  [ModalType.ITEMS_IN_ORDER_DRAWER]: DynamicItemsInOrderDrawer,
  [ModalType.STUDENT_PURCHASE_MODAL]: DynamicStudentPurchaseModal,
  [ModalType.STUDENT_VERIFICATION_HOW_IT_WORKS_MODAL]:
    DynamicStudentVerificationSuccessHowItWorksModal,
  [ModalType.STUDENT_VERIFICATION_EMAIL_MODAL]:
    DynamicStudentVerificationSuccessEmailModal,
  [ModalType.STUDENT_VERIFICATION_CODE_MODAL]:
    DynamicStudentVerificationSuccessCodeModal,
  [ModalType.STUDENT_REVERIFICATION_SUCCESS_MODAL]:
    DynamicReverificationSuccessModal,
  [ModalType.SHIPT_AI_LEARN_MORE_MODAL]: DynamicShiptAiLearnMoreModal,
  [ModalType.NEW_LIST_MODAL]: DynamicNewListAndEditListNameModal,
  [ModalType.LIST_ENTRYPOINT_MODAL]: DynamicListsEntryPointModal,
  [ModalType.UPGRADE_SUCCESS_MODAL]: DynamicUpgradeSuccessModal,
  [ModalType.OTHER_PLANS_DRAWER]: DynamicOtherPlansDrawer,
  [ModalType.SNAP_EBT_CARD_VERIFICATION_MODAL]:
    DynamicSnapEBTCardVerificationModal,
  [ModalType.TARGET_RED_CARD_MASTERCARD_MODAL]:
    DynamicTargetCircleMastercardModal,
  [ModalType.SNAP_EBT_PURCHASE_MODAL]: DynamicSnapEBTPurchaseModal,
  [ModalType.EBT_HOW_IT_WORKS_MODAL]: DynamicEBTHowItWorksModal,
  [ModalType.SNAP_EBT_ADDITIONAL_CHARGE_MODAL]: DynamicEBTAdditionalChargeModal,
  [ModalType.GLOBAL_STORE_SELECTION_DRAWER]: DynamicGlobalStoreSelectionDrawer,
  [ModalType.EXISTING_MEMBER_MODAL]: DynamicExistingMemberModal,
  [ModalType.USUALS_DRAWER]: DynamicUsualsDrawer,
  [ModalType.USUALS_STORE_SELECTION_DRAWER]: DynamicUsualsStoreSelectDrawer,
  [ModalType.PROMOTION_STORES_DRAWER]: DynamicPromotionStoresDrawer,
  [ModalType.GIFT_CARD_ORDER_OVERVIEW_MODAL]: DynamicGiftCardOrderOverviewModal,
  [ModalType.STORES_LIST_DRAWER]: DynamicStoresListDrawer,
}
