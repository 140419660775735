import { DynamicAuthNoticeModals } from '@/components/Modal/AuthNoticeModals/DynamicAuthNoticeModals'
import { useFeatureFlags } from '@/services/FeatureFlags/useFeatureFlags'
import { useUser } from '@/services/User/hooks'
import { useHandleDialog } from '@/state/Dialog/useHandleDialog'
import { getCookie } from '@/utils/cookies'
import { type PageRouteData } from '@/utils/setPageProps'
import { useEffect, useMemo } from 'react'

export const FACEBOOK_SUNSET_MODAL_SEEN = 'fb_modal_seen'

export const useFacebookSunsetModal = (routeData: PageRouteData) => {
  const { openDialog } = useHandleDialog()
  const {
    flags: { facebook_sunset: facebookSunsetEnabled },
  } = useFeatureFlags()
  const user = useUser()

  const facebookModalSeen = useMemo(
    () => getCookie(FACEBOOK_SUNSET_MODAL_SEEN),
    []
  )
  const shouldOpenModal = useMemo(() => {
    return (
      facebookSunsetEnabled &&
      !facebookModalSeen &&
      routeData?.authentication &&
      user?.last_login_was_fb
    )
  }, [
    facebookSunsetEnabled,
    facebookModalSeen,
    routeData?.authentication,
    user?.last_login_was_fb,
  ])

  useEffect(() => {
    if (shouldOpenModal) {
      openDialog(DynamicAuthNoticeModals, { type: 'facebook_sunset' })
    }
  }, [openDialog, shouldOpenModal])
}
